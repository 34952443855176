import { chakra } from '@chakra-ui/react';
import { FC } from 'react';

interface UnderlinedProps {
  color: string;
}

export const Underlined: FC<UnderlinedProps> = ({ color, children }) => (
  <chakra.span borderBottom="3px solid" borderColor={color}>
    {children}
  </chakra.span>
);
