import {Flex, Image, Box} from '@chakra-ui/react';
import {Layout} from 'components/shared';
import {Title} from 'components/shared/Title';
import lockYouInData from 'data/lockYouIn.json';
import {BgPattern} from "../shared/BgPattern";

export function LockYouIn() {
  const iconStyle = {
    width: "auto",
    aspectRatio: "1/1",
    display: "block",
  }
  return (
      <Layout
          bg="white"
          px="3rem"
          color="black"
          id="tutorial"
          pos="relative"
      >

        <Flex
            pos="relative"
            zIndex="2"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mx={{base: "0", md: "auto"}}
            my="16"
            gap="30px"
            maxWidth={{base: "100%", md: "90%"}}
        >
          <Title
              textAlign="center"
              dangerouslySetInnerHTML={{__html: lockYouInData.title}}
          />

          {lockYouInData.cards.map((card, i) => (
              <Flex
                  key={i}
                  bg={card.bg}
                  w={{base: "100%", md: "75%"}}
                  gap={{base: "1rem", md: "2rem"}}
                  alignItems="center"
                  p="1rem"
                  borderRadius="40px"
                  boxShadow="0px 4.55273px 4.55273px rgba(0, 0, 0, 0.25)"
              >
                <Flex
                    bg="white"
                    borderRadius="50%"
                    p="1rem"
                    w="84px"
                    h="84px"
                    boxSizing="border-box"
                >
                  <Image
                      sx={iconStyle}
                      src={`images/${card.icon}`}
                  />
                </Flex>
                <Box
                    w="auto"
                    maxWidth="calc(90% - 84px)"
                >
                  {card.content}
                </Box>
              </Flex>
          ))}

        </Flex>

        <BgPattern/>
      </Layout>
  );
}
