import {
  GridItem,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { RiCopperCoinFill } from 'react-icons/ri';
import { Title } from 'components/shared/Title';
import whatsInsideData from 'data/whatsInside.json';
import { Layout } from '../shared/Layout';

export function WhatsInside() {
  return (
    <Layout
      px={{ base: '1.5rem', md: '3.25rem', xl: '12rem' }}
      bg="white"
      color="black"
      id="features"
    >
      <Title mb="20" textAlign="center">
        {whatsInsideData.title}
      </Title>

      <Tabs isFitted variant="unstyled">
        <TabList mb="10" overflowX="auto">
          {whatsInsideData.tabs.title.map((title, i) => (
            <Tab
              key={title}
              whiteSpace="nowrap"
              borderBottom="3px solid"
              borderColor="black"
              mr={i === whatsInsideData.tabs.title.length - 1 ? 0 : 7}
              _selected={{ borderColor: 'brand.800' }}
              textTransform="uppercase"
              pb="4"
              fontSize="lg"
            >
              {title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {whatsInsideData.tabs.panel.map((panel) => (
            <TabPanel key={panel.title} p="0">
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <GridItem>
                  <Text
                    fontSize={{
                      base: '2xl',
                      lg: '3xl',
                    }}
                    fontWeight="bold"
                    mb={{ base: '1rem', md: '3.25rem' }}
                  >
                    {panel.title}
                  </Text>

                  <List spacing="3">
                    {panel.list.map((element) => (
                      <ListItem
                        key={element}
                        fontSize="lg"
                        alignItems="center"
                        lineHeight="1.25"
                        d="flex"
                        py="1"
                      >
                        <ListIcon fontSize="14px" color="red.400" as={RiCopperCoinFill} />
                        {element}
                      </ListItem>
                    ))}
                  </List>
                </GridItem>

                <GridItem gridRow={{ base: '1', md: 'auto' }}>
                  <Image src={`images/${panel.image}`} mb={{ base: '1.3rem', md: '0' }} />
                </GridItem>
              </SimpleGrid>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
