import { Helmet } from 'react-helmet';
import {
  Faq,
  FlowTemp,
  LockYouIn,
  Build,
  LokoProject,
  Personas,
  WhyLoko,
  WhatsInside,
  Customers,
} from 'components/Homepage';
import { Main } from 'components/shared/Main';
import '../index.css';
import { CookieConsent } from 'components/shared';
import helmetData from 'data/helmet.json';
import { Downloads } from 'components/Homepage/Downloads';

function IndexPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{helmetData.title}</title>
        <meta
          name="description"
          content="LOKO AI is an on-prem IDE to democratize AI, with special focus on hybrid low and full-code development, and cloud services."
        />
        <meta property="og:type" content="website" />
        <meta name="og:url" property="og:url" content="https://loko-ai.com" />
        <meta name="og:title" property="og:title" content={helmetData.title} />
        <meta name="og:description" property="og:description" content={helmetData.description} />
        <meta name="og:image" property="og:image" content={`/images/${helmetData.image}`} />
        <script src="/static/tracking.js" />
      </Helmet>
      <Main>
        <FlowTemp />
        <Build />
        <Personas />
        <Customers />
        <LokoProject />
        <WhatsInside />
        <WhyLoko />
        <LockYouIn />
        <Downloads />
        <Faq />
        <CookieConsent />
      </Main>
    </>
  );
}

export default IndexPage;
