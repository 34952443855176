import React, {useState} from 'react';
import {Grid, GridItem, Image, Button, Flex, Heading, Text, Box} from '@chakra-ui/react';
import {Layout} from 'components/shared';
import { FaArrowRight } from 'react-icons/fa';
import lokoProjectData from 'data/lokoProject.json';

export function LokoProject() {
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  const buttonStyles = {
    borderRadius: "40px",
    py: "2rem",
    pl: "2rem",
    pr: "4rem",
    position: "relative",
    span: {
      position: "absolute",
      right: "1rem",
    }
  }



  return (
      <Layout id="loko-project">
        <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)'}}
            maxW={{base: 'full', md: '80%'}}
            mx="auto"
            gap={6}
        >
          <GridItem colSpan={{base:1, md:6}}>
            <Heading color="brand.500"  mb="60px">{lokoProjectData.title}</Heading>
          </GridItem>
          <GridItem colSpan={1}>
            <Flex width="full" flexDir="column" id="videos" gap="2rem">
              {lokoProjectData.thumbnails.map((thumb, index) => (
                  <Button
                      key={index}
                      width="100%"
                      fontSize="1.5rem"
                      fontWeight="regular"
                      sx={buttonStyles}
                      px={{base: '2rem', md: '4rem'}}
                      color={activeThumbnail === index ? 'white' : 'black'}
                      bgColor={activeThumbnail !== index ? 'brand.100' : undefined}
                      bgGradient={activeThumbnail === index ? 'linear(to-r, brand.500, brand.600)' : undefined}
                      _hover={!activeThumbnail ? {bgGradient: 'linear(to-l, brand.500, brand.600)'} : {bgGradient: 'linear(to-r, brand.500, brand.600)'}}
                      _active={{bgGradient: 'linear(to-r, brand.500, brand.600)'}}
                      onClick={() => setActiveThumbnail(index)}
                      rightIcon={<FaArrowRight fontSize="1rem"/>}
                  >
                    {thumb.title}
                  </Button>
              ))}
            </Flex>
          </GridItem>
          <GridItem colSpan={{base: 1, md: 3}} colStart={{base: 1, md: 3}}>
            <Box>
              <Text size="24px" mb="20px">{lokoProjectData.thumbnails[activeThumbnail]?.description}</Text>
              <Image src={`images/${lokoProjectData.thumbnails[activeThumbnail]?.source}`}/>
            </Box>
          </GridItem>
        </Grid>
      </Layout>
  );
}
