import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Box } from '@chakra-ui/layout';
import { Layout } from 'components/shared';
import { Title } from 'components/shared/Title';
import faqData from 'data/faq.json';

export function Faq() {
  return (
    <Layout
      px={{
        base: '2rem',
        sm: '2rem',
        md: '8rem',
        lg: '12rem',
        xl: '16rem',
      }}
      id="faq"
    >
      <Title textAlign="center" pl="4" my="8">
        FAQs
      </Title>
      <Accordion allowToggle>
        {faqData.faqs.map((f, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton _focus={{ outline: 'none' }} _active={{ outline: 'none' }} py="1.5">
                <Box
                  flex="1"
                  textAlign="left"
                  fontSize={{
                    base: 'md',
                    md: 'lg',
                  }}
                  color="brand.700"
                >
                  {f.title}
                </Box>
                <AccordionIcon color="gray.600" fontSize="2xl" />
              </AccordionButton>
            </h2>
            <AccordionPanel pt="4" pb="8" color="gray.500">
              {f.content}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Layout>
  );
}
