import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Select,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Layout } from 'components/shared';
import { Subtitle } from 'components/shared/Subtitle';
import { Title } from 'components/shared/Title';
import { Underlined } from 'components/shared/Underlined';
import { useCompositeState } from 'hooks/state';
import { SiApple, SiLinux, SiWindows } from 'react-icons/si';
import { useEffect } from 'react';
import { validateEmail } from 'utils';
import axios from 'axios';

const isBrowser = () => typeof window !== 'undefined';

const roles = ['', 'CEO', 'CTO', 'Manager', 'Developer', 'Marketing', 'Operations', 'Other'];

function getOperatingSystem() {
  let operatingSystem = 'Not known';
  if (!isBrowser()) {
    return operatingSystem;
  }
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'Mac';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'Linux';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux';
  }

  return operatingSystem;
}

const headers = {
  accept: 'application/json',
  'content-type': 'application/json',
  'api-key':
    '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',
};

function validateForm(state: any) {
  if (!state.firstName) {
    return false;
  }
  if (!state.lastName) {
    return false;
  }
  if (!state.email) {
    return false;
  }
  if (!state.role) {
    return false;
  }
  if (!state.privacy) {
    return false;
  }
  if (!state.tos) {
    return false;
  }
  if (!state.marketing) {
    return false;
  }
  return true;
}

function sendForm(state: any) {
  const attributes = {
    NOME: state.firstName,
    COGNOME: state.lastName,
    ROLE: roles.indexOf(state.role),
    EMAIL: state.email,
    TERMS_OF_USE: state.tos,
    OPT_IN: state.marketing,
  };

  const data = { email: state.email, attributes, listIds: [4] };

  console.log(state);
  const subscriptionUrl = 'https://api.sendinblue.com/v3/contacts';

  axios
    .post(subscriptionUrl, data, {
      headers,
    })
    .catch((err) => {
      console.log(err);
    });
}

export function Downloads() {
  const state = useCompositeState({
    privacy: false,
    tos: false,
    marketing: false,
    showDownloads: false,
    firstName: '',
    lastName: '',
    role: '',
    email: '',
  });
  const valid = useCompositeState({});
  const os = getOperatingSystem();

  useEffect(() => {
    if (state.email) {
      validateEmail(state.email).then((res: any) => (valid.email = res.valid));
    }
  }, [state.email]);
  const allValid = validateForm(state) && valid.email;

  return (
    <Layout color="white" id="downloads">
      <Title textAlign="center">Just one step</Title>
      <Subtitle textAlign="center">
        <Box>Ready to experience the power of LOKO AI Community Edition?</Box>
        <Box>
          Simply fill out the form below and we'll send you an email with everything you need to get
          started, including direct download links{' '}
        </Box>
        for the free version of LOKO AI, its user guide, video tutorials and a demo project to get
        started.
      </Subtitle>
      {!state.showDownloads && (
        <Center bg="white" p="1rem">
          {/* <Image w="40%" src="images/first-image.png" /> */}
          <Stack fontSize="sm" p="10rem">
            <Text color="black">First Name *</Text>
            <Input
              bg="#EEEEEE"
              color="black"
              h="3rem"
              value={state.firstName}
              onChange={(e) => (state.firstName = e.target.value)}
            />
            <Text color="black" fontSize="sm">
              Last Name *
            </Text>
            <Input
              bg="#EEEEEE"
              type="text"
              color="black"
              h="3rem"
              value={state.lastName}
              onChange={(e) => (state.lastName = e.target.value)}
            />
            <Text color="black" fontSize="sm">
              Email *
            </Text>
            <Input
              bg="#EEEEEE"
              type="text"
              color="black"
              h="3rem"
              value={state.email}
              onChange={(e) => (state.email = e.target.value)}
              isInvalid={state.email !== '' && !valid.email}
            />
            {state.email !== '' && !valid.email && (
              <Text size="xsm" color="red">
                Invalid email
              </Text>
            )}
            <Text color="black" fontSize="sm">
              Role *
            </Text>
            <Select
              variant="filled"
              color="black"
              h="3rem"
              value={state.role}
              onChange={(e) => (state.role = e.target.value)}
              bg="#EEEEEE"
            >
              {roles.map((el, i) => (
                <option style={{ background: 'white' }} key={i}>
                  {el}
                </option>
              ))}
            </Select>

            <Checkbox
              colorScheme="gray"
              color="black"
              isChecked={state.tos}
              onChange={(e) => (state.tos = e.target.checked)}
              borderColor="gray.200"
            >
              I have read and agree to the{' '}
              <Link href="/docs/Loko AI - Terms of Use.pdf" target="_blank" color="blue">
                Terms of Use*
              </Link>{' '}
              of LOKO AI
            </Checkbox>
            <Checkbox
              colorScheme="gray"
              isChecked={state.privacy}
              onChange={(e) => (state.privacy = e.target.checked)}
              color="black"
              borderColor="gray.200"
            >
              I agree to receive your newsletters and accept the{' '}
              <Link href="/policy" target="_blank" color="blue">
                Data Privacy Policy*
              </Link>
            </Checkbox>
            <Checkbox
              isChecked={state.marketing}
              onChange={(e) => (state.marketing = e.target.checked)}
              color="black"
              borderColor="gray.200"
            >
              We use Sendinblue as our marketing platform. By clicking here you acknowledge that the
              informations you provided will be transferred to Sendiblue for processing in
              accordance with their{' '}
              <Link
                href="https://www.sendinblue.com/legal/termsofuse/"
                target="_blank"
                color="blue"
              >
                Terms of Use*
              </Link>{' '}
            </Checkbox>
            <Spacer />
            <Center pt="1rem">
              <Button
                disabled={!allValid}
                w="20%"
                colorScheme="green"
                onClick={(e) => {
                  sendForm(state);
                  state.showDownloads = true;
                }}
              >
                Get Started
              </Button>
            </Center>
          </Stack>
        </Center>
      )}
      {state.showDownloads && (
        <Center>
          <Stack w="100%" bg="orange.500" px="20%" py="6rem" spacing={8}>
            <Title textAlign="center">Thank you {state.firstName}</Title>
            <Subtitle textAlign="center">Check out your inbox to get started with LOKO!</Subtitle>
            <Spacer />
            {/* <HStack mt="8">
              <Button
                as={Link}
                href="https://github.com/loko-ai/loko/releases/download/1.0.0-beta/LOKO-Linux-1.0.0-beta.deb"
                aria-label=""
                w="20%"
                h="5rem"
                borderRadius={'20px'}
                bg={os === 'Linux' ? 'green' : 'black'}
                leftIcon={
                  <Center>
                    <Icon w={30} h={30} as={SiLinux} />
                  </Center>
                }
              >
                <Text fontSize={'xl'}>Linux</Text>
              </Button>
              <Spacer />
              <Button
                as={Link}
                href="https://github.com/loko-ai/loko/releases/download/1.0.0-beta/LOKO-Windows-1.0.0-beta-Setup.exe"
                aria-label=""
                w="20%"
                h="5rem"
                bg={os === 'Windows' ? 'green' : 'black'}
                borderRadius={'20px'}
                leftIcon={<Icon w={30} h={30} as={SiWindows} />}
              >
                <Text fontSize={'xl'}>Windows</Text>
              </Button>
              <Spacer />
              <Button
                aria-label=""
                w="20%"
                h="5rem"
                borderRadius={'20px'}
                bg={os === 'Mac' ? 'green' : 'black'}
                leftIcon={<Icon mb="2" w={30} h={30} as={SiApple} />}
                as={Link}
                href="https://github.com/loko-ai/loko/releases/download/1.0.0-beta/LOKO-Mac-1.0.0-beta-Installer.dmg"
              >
                <Text fontSize={'xl'}>Mac</Text>
              </Button>
            </HStack> */}
          </Stack>
        </Center>
      )}
    </Layout>
  );
}
