import {Flex} from '@chakra-ui/layout';
import {Image} from '@chakra-ui/image';
import {Layout} from 'components/shared';
import {Title} from 'components/shared/Title';
import {Underlined} from 'components/shared/Underlined';
import customersData from 'data/customers.json';

export function Customers() {

  return (
      <Layout bg="white" color="black" px={{base: '2rem', md: '6rem'}} id="discover">
        <Title textAlign="center" mb="2rem">
          <Underlined color="brand.800">{customersData.title}</Underlined>
        </Title>

        <Flex
            justifyContent="center"
            gap="5rem"
            mb="2rem"
            flexWrap="wrap"
        >
          {customersData.customers.map((customer, i) => (
              <Image
                  key={i}
                  src={`images/${customer.image}`}
                  alt={customer.title}
                  display="block"
                  height={customer.height || '50px'}
                  width={{ md: 'auto'}}
              />
          ))}
        </Flex>
      </Layout>
  );
}
