import { FC } from 'react';
import { Docs } from 'components/Homepage/Docs';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const Main: FC = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
);
