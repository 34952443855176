export function BgPattern() {
  return (
      <svg style={{position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px", zIndex:0, pointerEvents: "none"}}>
        <pattern id="pattern-heroundefined" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"
                 patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-heroundefined)"></rect>
      </svg>
  );
}

export function BgPatternDark() {
  return (
      <svg style={{position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px", zIndex:0, pointerEvents: "none"}}>
        <pattern id="pattern-heroundefined-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"
                 patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#000000"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-heroundefined-1)"></rect>
      </svg>
  );
}