import {Grid} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import {Layout} from 'components/shared';
import {Card} from 'components/shared/Card';
import {Title} from 'components/shared/Title';
import buildData from 'data/build.json';
import {BgPattern} from "../shared/BgPattern";

export function Build() {

  const cardStyle = {
    p: "2rem",
    mb: {base: "2rem", xl: "3.5rem"},
    borderRadius: "40px",
    boxShadow: "0px 4.55273px 4.55273px rgba(0, 0, 0, 0.25)",
    aspectRatio: {base:null, xl:"1/ 1"},
    height: {base:"100%", xl:"auto"},
  };

  const titleStyle = {
    u : {
      textDecorationColor: "brand.500",
    }
  }

  return (
      <Layout
          bg="white"
          color="black"
          px={{base: '2rem', md: '6rem'}}
          id="discover"
          position="relative"
      >
        <div style={{zIndex: 2, position: "relative"}}>
          <Title
              textAlign="center"
              mb="3rem"
              sx={titleStyle}
              dangerouslySetInnerHTML={{__html: buildData.title}}
          />

          <Grid
              templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)'}}
              gap="3rem"
              maxW={{base: '100%', xl: '90%'}}
              mx="auto"
          >
            {buildData.cards.map((card, i) => (
                <Card
                    key={i}
                    icon={`images/${card.icon}`}
                    bgColor={card.bgColor}
                    sx={cardStyle}
                >
                  <Text fontSize={{base: "20px", md:"18px"}}>{card.title}</Text>
                </Card>
            ))}
          </Grid>
        </div>

        <BgPattern />

      </Layout>
  );
}
