import { Image } from '@chakra-ui/image';
import { Box, BoxProps, Center, Text, Flex } from '@chakra-ui/layout';
import { Title } from './Title';

interface CardProps extends BoxProps {
  image?: string;
  title?: string;
  subtitle?: string;
  icon?: string;
  children?: React.ReactNode;
}

export function Card({ image, title, subtitle, icon, children, ...rest }: CardProps) {
  return (
      <Flex textAlign="center" direction="column" justifyContent="flex-start" {...rest}>
        <Box>
          <Center>
            {image && <Image src={image} height="200px" />}
            {icon &&
              <Box
                bgColor="white"
                borderRadius="50%"
                p="1rem"
                my={{ base: '1rem', md: '2rem' }}
              >
                <Image src={icon} height="80px" />
              </Box>
            }
          </Center>
          {title && <Title fontSize="3xl" mt="4" mb="0">{title}</Title>}
          {subtitle && <Text fontSize="md">{subtitle}</Text>}
        </Box>
        {children}
      </Flex>

  );
}
