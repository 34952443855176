import {Flex, Grid, GridItem, Box, Text, Image} from '@chakra-ui/react';
import {Layout} from 'components/shared';
import {Title} from 'components/shared/Title';
import personasData from 'data/personas.json';
import {BgPatternDark} from "../shared/BgPattern";

export function Personas() {
  const titleStyle = {
    background: 'brand.100',
    color: 'black',
    display: 'inline-block',
    fontSize: '40px',
    fontWeight: '700',
    p: '20px',
    borderRadius: '30px',
    boxShadow: 'lg',
    width: "100%"
  };

  const BoxOutlineStyle = {
    border: '1px solid white',
    fontSize: '25px',
    borderRadius: '40px',
    p: '2rem',
    color: 'white',
  }

  const cardPersonaStyle = {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  }

  const roundedImgStyle = {
    p: "2rem",
    mt: {base: '-50%', sm: '-10rem',md: '-50%'},
    mb: '2rem',
    bgColor: "brand.100",
    borderRadius: "50%",
    w: "50%",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    aspectRatio: 1,
    position: 'relative',
  }

  return (
      <Layout
          bgGradient="linear(to-b, brand.500, brand.600)"
          color="black" px={{base: '2rem', md: '6rem'}}
          id="discover"
          position="relative"
      >
        <BgPatternDark/>
        <Grid
            position="relative"
            templateColumns="repeat(12, 1fr)"
            gap="2rem"
            rowGap="4rem"
            zIndex={2}
        >
          <GridItem
              colSpan={{base: 12, lg: 5}}
              order={{base: -1, lg: 0}}
          >
            <Title
                sx={titleStyle}
                dangerouslySetInnerHTML={{__html: personasData.title}}
            />
          </GridItem>

          <GridItem
              colSpan={{base: 12, lg: 7}}
          >
            <Flex justifyContent="space-between" alignItems="center" height="100%">
              {personasData.cards.map((card, i) => (
                  <Flex key={i} w="50%" justifyContent="center">
                    <Text
                        color="white"
                        textAlign="center"
                        fontSize="28px"
                        zIndex={1}
                    >{card.pretitle}</Text>
                  </Flex>

              ))
              }
            </Flex>
          </GridItem>

          <GridItem
              colSpan={{base: 12, lg: 5}}
              order={{base: -1, lg: 0}}
          >
            <Box sx={BoxOutlineStyle}>
              <Text
                  dangerouslySetInnerHTML={{__html: personasData.subtitle}}
              />
            </Box>
          </GridItem>

          <GridItem colSpan={{base: 12, lg: 7}}>
            <Grid
                templateColumns={{base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)"}}
                gap="1rem"
            >
              {personasData.cards.map((card, i) => (
                  <Flex
                      key={i}
                      sx={cardPersonaStyle}
                  >
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        background="brand.50"
                        h="100%"
                        p="2rem"
                        pt="4rem"
                        borderRadius="40px"
                        boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                        mt={{base: "3rem", md: "0"}}
                    >
                      <Box
                          sx={roundedImgStyle}
                          _before={{
                            background: card.circleBg,
                            content: '" "',
                            display: 'block',
                            width: '100%',
                            position: 'absolute',
                            borderRadius: '50%',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            paddingBottom: '100%',
                            transform: 'scale(.5)',
                          }}
                          _after={{
                            content: '" "',
                            display: 'block',
                            width: '100%',
                            position: 'absolute',
                            borderRadius: '50%',
                            zIndex:2,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            bgImage:`url(images/${card.image})`,
                            bgRepeat: "no-repeat",
                            bgSize: "auto 80%",
                            bgPosition: "center",
                          }}
                      />
                      <Text
                          fontSize="25px"
                          fontWeight="700"
                          mb="2rem"
                      >{card.title}</Text>
                      <Text fontSize="23px">{card.subtitle}</Text>
                    </Flex>
                  </Flex>
              ))}
            </Grid>
          </GridItem>
        </Grid>


      </Layout>
  );
}
