import {Box, Button, Flex, IconButton, Image} from '@chakra-ui/react';
import {Layout} from 'components/shared';
import {Title} from 'components/shared/Title';
import {FaArrowDown} from 'react-icons/fa';
import flowData from 'data/flowTemp.json';

export function FlowTemp() {
  return (
      <>
        <Layout
            bgGradient="linear(to-l, brand.800, brand.100, brand.500)"
            // minHeight="100vh"
            color="black"
            as="main"
            pos="relative"
            p={{base: '6rem 2rem', md: '0'}}
            overflow="hidden"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mt={{base: '0', md: '90px'}}
            minHeight={{base: '100vh', md: 'calc(100vh - 90px)'}}
            boxSizing='border-box'
            py={{base: '4rem', md: '6rem'}}
        >
          <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              pb={{base: '4rem', md: '6rem'}}
              // pos="absolute"
              // top="50%"
              // transform="translateY(-50%)"
              zIndex={8}
          >
            <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                maxW={{base: 'full', sm: '65%'}}
                letterSpacing="-0.05rem"
                textAlign="center">
              <Title as="h1"
                     lineHeight="1"
                     fontSize={{base: '2.25rem', sm: '3rem', lg: '2.6rem'}}
                     mb="2rem"
              >
                {flowData.title}
              </Title>
              <Image
                  src={`images/${flowData.image}`}
                  maxWidth="500px"
                  h="auto"
                  w="100%"
                  alt={flowData.title}
              />
              <Button
                  mt="2rem"
                  color="white"
                  bg="black"
                  _hover={{color: 'white', bg: 'gray.800'}}
                  as="a"
                  href="https://www.linkedin.com/pulse/live-tech-announces-its-first-honorable-mention-gartner-magic-quadrant/?utm_source=sito+LOKO&utm_medium=sito+Loko&utm_campaign=mention+Gartner+da+sito+a+LinkedIn&utm_id=articolo+mention+Gartner+da+sito"
                  target="_blank"
                  title={flowData.buttonText}
                  px={{base: 4, md: 8}}
              >
                {flowData.buttonText}
              </Button>
            </Flex>
          </Flex>

          <IconButton
              rounded="full"
              pointerEvents="auto"
              color="white"
              bg="black"
              _hover={{bg: 'gray.800', color: 'white'}}
              size="lg"
              as="a"
              href="#discover"
              pos="absolute"
              bottom={5}
              left="50%"
              transform="translate(-50%)"
              zIndex={8}
              icon={<FaArrowDown/>}
              aria-label="Discover more"
          />
        </Layout>
      </>
  );
}
