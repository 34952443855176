import { SimpleGrid, Box, Flex } from '@chakra-ui/layout';
import { Layout } from 'components/shared';
import { Title } from 'components/shared/Title';
import whylokoData from 'data/whyloko.json';
import { Button, Text } from '@chakra-ui/react';

export function WhyLoko() {
  const gridStyle = {
    maxWidth: 'container.xl',
    justifyContent: 'center',
    gap: '60px',
  };

  const titleStyle = {
    background: 'black',
    color: 'white',
    display: 'inline-block',
    fontSize: '40px',
    p: '20px',
    borderRadius: '30px',
    mb: '80px',
    boxShadow: 'lg',
  };
  const percentage = {
    color: 'white',
    fontWeight: 400,
    fontSize: '108px',
    fontFamily: 'Impact',
  };

  const cardTitle = {
    fontSize: '37px',
    fontWeight: '700',
    lineHeight: '40px',
    height: '80px',
    noOfLines: [2, 3],
  };

  const cardDescription = {
    backgroundColor: 'pink',
    px: '50px',
    borderRadius: '30px',
    minHeight: '150px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'lg',
  };

  return (
    <Box w="100%" h="100%" bgGradient="linear(to-b, brand.100, brand.400)">
      <Layout bg="primary" color="black" px={{ base: '2rem', md: '6rem' }} id="discover">
        <Title sx={titleStyle}>{whylokoData.title}</Title>

        <SimpleGrid sx={gridStyle} columns={3} minChildWidth="280px" mx="auto">
          {whylokoData.cards.map((card, i) => (
            <Flex key={i} mb="3.5rem" direction="column" alignItems="center" textAlign="center">
              <Text sx={cardTitle}>{card.title}</Text>
              <Text sx={percentage}>{card.percentage}</Text>
              <Text sx={cardDescription}>{card.description}</Text>
            </Flex>
          ))}
        </SimpleGrid>

        <Flex justifyContent="center">
          <Button
            mt="2rem"
            color="white"
            background="black"
            bg="brand.700"
            _hover={{ color: 'black', bg: 'brand.200' }}
            as="a"
            href="/#downloads"
            title="Downloads"
            size="lg"
            minW="xs"
            fontSize="27px"
            boxShadow="lg"
          >
            Get_LOKO
          </Button>
        </Flex>

        <Text mt="30px" textAlign="center" fontSize="12px">
          *{whylokoData.note}
        </Text>
      </Layout>
    </Box>
  );
}
